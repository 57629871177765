import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Seo from "../components/Seo"

const PageTitle = styled.section`
  grid-column: 2 / span 6;
  grid-row: 3;
  display: flex;
  align-items: center;
  margin-bottom: 5rem;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    margin-bottom: 0;
  }
`
const ContactSection = styled.section`
  grid-column: 2 / span 12;
  grid-row: 4;
  margin-bottom: 23rem;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    margin-bottom: 10rem;
  }
  @media screen and (max-width: 37rem) {
    margin-bottom: 5rem;
  }
`
const DisclaimerSection = styled.section`
  grid-column: 4 / span 6;
  grid-row: 5;
  margin-bottom: 12rem;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 5;
    margin-bottom: 5rem;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
  }
  @media screen and (max-width: 37rem) {
    margin-bottom: 2.5rem;
  }
`
const Subsectionheadline = styled.h2`
  padding-top: 2rem;
  font-size: 1.9em;
  letter-spacing: 0.04em;
  font-family: "Archivo Black", sans-serif;
  @media screen and (max-width: 90rem) {
    margin-top: 4rem;
    font-size: 1.3em;
  }
  @media screen and (max-width: 63rem) {
    margin-top: 2.5rem;
    font-size: 1em;
  }
`

export default function Imprint() {
  return (
    <Layout>
      <Seo
        title={
          "Imprint"
        }
        description={
          "Make Web Sustainable informs about sustainable web design and creates a sustainable mindset/toolset with the goal to reduce the CO2 emission of websites"
        }
        keywords={[
          "Make Web Sustainable",
          "Sustainable Web Design",
          "Home",
          "Introduction",
          "CO2 Emission",
        ]}
      />
      <PageTitle>
        <h1>Imprint</h1>
      </PageTitle>
      <ContactSection>
        <Subsectionheadline>General information according to para. 5 TMG</Subsectionheadline>
        <p>
          Selina, Pali
          <br />
          Briachstrasse 22
          <br />
          88250 Weingarten
        </p>
        <Subsectionheadline>Contact</Subsectionheadline>
        <p>you@makewebsustainable.com</p>
      </ContactSection>
      <DisclaimerSection>
        <h2>DISCLAIMER</h2>
        <Subsectionheadline>Liability for contents</Subsectionheadline>
        <p>
          All contents of my website have been created with the utmost care and
          to the best of my knowledge. However, I cannot guarantee the accuracy,
          completeness and timeliness of the content. As a service provider, I
          am responsible for my own content on these pages under the general
          laws according to para. 7 para.1 TMG. According to para. 8 to 10 TMG,
          however, I am not obligated as a service provider to monitor
          transmitted or stored third-party information or to investigate
          circumstances that indicate illegal activity. Obligations to remove or
          block the use of information under the general laws remain unaffected.
          <br /> <br />
          However, liability in this regard is only possible from the point in
          time at which a concrete infringement of the law becomes known. If I
          become aware of the above-mentioned infringements, I will remove this
          content immediately.
        </p>
        <Subsectionheadline>Liability for links</Subsectionheadline>
        <p>
          My website contains links to third-party websites, I do not have any
          control of their contents. As a result, I cannot accept any liability
          for these third-party contents either. The respective provider or
          operator of the pages is always responsible for the contents of the
          linked pages. The linked pages were checked for possible statutory
          violations at the time of the linking. No unlawful contents could be
          identified at the time of the linking.
          <br /> <br />
          However, I cannot reasonably be expected to carry out a permanent
          check of the contents of the linked pages without concrete evidence of
          an infringement. When I become aware of infringements, I will remove
          such links immediately.
        </p>
        <Subsectionheadline>Copyright law</Subsectionheadline>
        <p>
          The contents and works on this page created by the page operator are
          subject to german copyright law. The copying, editing, dissemination
          and any kind of utilisation beyond the limits of copyright law require
          the respective author’s or creator’s written consent. Downloads and
          copies of this page are only permitted for private, non-commercial
          use.
          <br /> <br />
          If the contents on this page were not created by the operator, the
          third-party copyrights will be observed. In particular-, third party
          contents will be identified as such. Should you nonetheless notice a
          copyright infringement, I request you to notify me accordingly. When I
          become aware of infringements, I will remove such contents
          immediately.
        </p>
      </DisclaimerSection>
    </Layout>
  )
}
